var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Card",
    {
      style: { backgroundColor: _vm.backgroundColor },
      attrs: { padding: 0, bordered: _vm.bordered },
    },
    [
      _c(
        "div",
        {
          staticClass: "card-content card4",
          style: { backgroundImage: _vm.backgroundImage },
        },
        [
          _c(
            "div",
            { staticClass: "card-body", style: { height: _vm.cardHeight } },
            [
              _c(
                "div",
                {
                  staticClass: "card-title",
                  style: {
                    color: _vm.titleColor,
                    fontSize: _vm.titleSize,
                    fontWeight: _vm.titleWeight,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "card-time",
                    style: {
                      color: _vm.timeColor,
                      fontSize: _vm.timeSize,
                      fontWeight: _vm.timeWeight,
                      marginBottom: _vm.timeBottom,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.time) + " ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "card-description",
                    style: {
                      color: _vm.descriptionColor,
                      fontSize: _vm.descriptionSize,
                      fontWeight: _vm.descriptionWeight,
                      marginBottom: _vm.descriptionBottom,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.description) + " ")]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }